import fetch from 'cross-fetch'
import withQuery from './withQuery'
import { showError, hideError, handleError } from './index'

export const LOAD_DEVICE_SUCCESS = 'LOAD_DEVICE_SUCCESS';
function loadDeviceSuccess(json) {
	return {
		type: LOAD_DEVICE_SUCCESS,
		payload: json
	}
}

export const LOADING_DEVICE = 'LOADING_DEVICE';
function loadingDevice() {
	return {
		type: LOADING_DEVICE
	}
}

export function fetchDevice(deviceId) {
	return (dispatch, getState) => {
		if (deviceId===undefined) return;
		dispatch(hideError());
		dispatch(loadingDevice());
		let token = getState().app.token;
		return fetch(withQuery('/api/device.json', {"device-id": deviceId}), {
					headers: {
						'Authorization': token
					}
			})
			.then(handleError)
			.then(response => response.json())
			.then(json => {
				dispatch(loadDeviceSuccess(json));
				return json;
			})
			.catch(error => {
				dispatch(showError(error));
				dispatch(loadDeviceSuccess(null));
			});
	}
}

export const DELETING_DEVICE = 'DELETING_DEVICE';
function deletingDevice(deleting) {
	return {
		type: DELETING_DEVICE,
		payload: deleting===undefined ? true : deleting
	}
}

export const DEVICE_DELETED = 'DEVICE_DELETED';
function deviceDeleted(deviceId) {
	return {
		type: DEVICE_DELETED,
		payload: deviceId
	}
}

export function deleteDevice(deviceId) {
	console.debug("deleteDevice : " + deviceId);

	return (dispatch, getState) => {
		if (deviceId===undefined) return;
		let state = getState();
		dispatch(hideError());
		dispatch(deletingDevice());
		let token = state.app.token;
		return fetch(withQuery('/api/device.json', {"id": deviceId}), {
				method: "delete",
				headers: {
					'Authorization': token,
					'Content-Type': 'application/json'
				}
			})
			.then(handleError)
			.then(response => {
				dispatch(deviceDeleted(deviceId));
				return response;
			})
			.catch(error => {
				dispatch(showError(error));
				dispatch(deletingDevice(false));
			});
	}
}
