import React from "react";
import { Container, Jumbotron, Row, Col } from 'reactstrap';

import Logo from '../images/dwine.svg'

export const Home = () => (
	<Container>
		<Jumbotron>
			<Row>
				<Col md="4">
					<img width="100%" src={Logo} alt="DWine System Logo" />
				</Col>
				<Col md="8">
					<h1 className="display-6">DWine Cloud API</h1>
					<p className="lead">Frontend</p>
					<hr className="my-2" />
					<p>Interne Benutzeroberfläche zur Verwaltung der DWine Cloud API</p>
				</Col>
			</Row>
		</Jumbotron>
	</Container>
)
