import React, {Component, useState, useEffect} from "react"

import { fetchCustomer, deleteCustomer, deleteDeviceOfCurrentCustomer } from '../../actions/customers'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, CardSubtitle, CardText,
 	Form, FormGroup, Label, Input, Button, Modal, ModalBody, ModalHeader, ModalFooter, Badge} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import fetch from 'cross-fetch'
import withQuery from '../../actions/withQuery'
import { store } from '../../index.js'

class DeleteDialog extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isModal : props.visible,
			title : props.title,
			body : props.body,
			action : props.action
		}
	}

	toggle = () => {
		this.setState({
			isModal: !this.state.isModal
		})
	}

	render() {
		const { isModal, title, body, action } = this.state;

		return (
			<Modal isOpen={isModal} toggle={this.toggle}>
				<ModalHeader toggle={this.toggle}>{title}</ModalHeader>
				<ModalBody>{body}</ModalBody>
				<ModalFooter>
					<Button color="danger" onClick={() => {this.toggle(); action();}}>Löschen</Button>
					<Button color="secondary" onClick={this.toggle}>Abbrechen</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const DeviceBadge = (props) => {
	const { type } = props;
	if (type==="app") return (<Badge style={{backgroundColor: "#7cba00"}}>App</Badge>);
	else if (type==="master") return (<Badge style={{backgroundColor: "#55aa00"}}>DWine</Badge>);
	else if (type==="shop") return (<Badge style={{backgroundColor: "#aaaaff"}}>Woo</Badge>);
}

const Device = (props) => {
	const { device } = props;
	const toLog = "/device/"+device.id+"/log";
	const created = device.created ? moment(device.created).format("LLL") : null;
	const lastActive = device.lastActive ? moment(device.lastActive).format("LLL") : null;

	const [ data, setData ] = useState({isLoading: true, device: null});
	const fetchData = async() => {
		let token = store.getState().app.token;
		fetch(withQuery('/api/device-status.json', {"device-id": device.id}), {
			headers: {
				'Authorization': token
			}
		})
		.then(response => response.json())
		.then(json => {
			setData({isLoading: false, device: json});
		});
	};
	useEffect(() => {
		fetchData();
	}, []);

	return (
		<Col sm="6" md="4" lg="3" className="mb-2">
			<Card>
				{device.isTemporary ?
					<CardHeader>Gerät #{device.number} (Temp) <DeviceBadge type={device.type} /></CardHeader>
					:
					<CardHeader>Gerät #{device.number} <DeviceBadge type={device.type} /></CardHeader>
				}
				<CardBody>
					<CardSubtitle><small>ID: {device.id}</small></CardSubtitle>
					<CardText><abbr title={device.lastUserAgent}>{device.description}</abbr><br />
						<FontAwesomeIcon icon={["far", "calendar-plus"]} /> {created}<br />
						<FontAwesomeIcon icon={["far", "clock"]} /> {lastActive}<br />
						<FontAwesomeIcon icon={["fas", "exchange-alt"]} /> {data.isLoading ?
							<FontAwesomeIcon icon="spinner" spin />
							:
							<span>{data.device.pendingTransfers} ({data.device.records})</span>
						}
					</CardText>
				</CardBody>
				<CardFooter className="text-right">
					<Button className="mr-1" color="danger" onClick={props.deleteAction}>Löschen</Button>
					<Link to={toLog} className="btn btn-primary">Log</Link>
				</CardFooter>
			</Card>
		</Col>
	)
}

class Customer extends Component {

	constructor(props) {
		super(props);
		this.deleteDialog = null;
		this.deleteDeviceDialog = null;
	}

	componentDidMount() {
		console.log("did mount.");
		if (!this.props.isNetworking) this.props.fetch(this.props.customerName);
	}

	/*removeDevice = (id) => {
		alert(id);
	}*/

	deleteDevice = (id, name) => {
		this.deleteDeviceDialog.setState({
			title: "Gerät löschen?",
			body: "Möchten Sie das Gerät '"+name+"' wirklich löschen?",
			action: () => this.props.removeDevice(id)
		});
		this.deleteDeviceDialog.toggle();
		//alert(id+" "+name);
	}

	render() {
		const { isNetworking, customer, match, remove } = this.props;

		if (isNetworking || customer==null || customer.devices===undefined) {
			return (
				<Container>
					<h2>Kunde</h2>
					<p><FontAwesomeIcon icon="spinner" spin /></p>
				</Container>
			)
		}

		const toDetails = match.url+"/edit";
		const created = moment(customer.created).format('LLL');
		const validUntil = customer.validUntil ? moment(customer.validUntil).format('LLL') : "";
		const login = customer.login ? moment(customer.login).format('LLL') : "";
		return (<React.Fragment>
			<DeleteDialog
				ref={(element) => {this.deleteDialog=element}}
				title="Kunde löschen?"
				body={"Möchten Sie den Kunden "+customer.username+" wirlich löschen?"}
				action={() => remove(customer.username)} />
			<DeleteDialog
				ref={(element) => {this.deleteDeviceDialog=element}} />
			<Container className="mb-2">
			  <h2>Kunde {customer.username} {customer.currentSessionId ?
				  <Badge color="success">online</Badge>
				  :
				  <Badge color="secondary">offline</Badge>
			  } {!customer.isEnabled ? <Badge color="warning">disabled</Badge> : ""}</h2>
			  <Row>
				<Col md="6" className="mb-2">
					<Card>
						<CardHeader>Kundeninformation</CardHeader>
						<CardBody>
							<Form>
								<FormGroup row>
									<Label for="created" sm={4}>Angelegt am:</Label>
									<Col sm={8}>
										<Input type="datetime" name="created" id="created" readOnly value={created}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="description" sm={4}>Beschreibung:</Label>
									<Col sm={8}>
										<Input type="datetime" name="description" id="description" readOnly value={customer.description}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="validUntil" sm={4}>Gültig bis:</Label>
									<Col sm={8}>
										<Input type="datetime" name="validUntil" id="validUntil" readOnly value={validUntil}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="maxDevices" sm={4}>Verfügbare Geräte:</Label>
									<Col sm={8}>
										<Input type="number" name="maxDevices" id="maxDevices" readOnly value={customer.maxDevices}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="currentDeviceCount" sm={4}>Angemeldete Geräte:</Label>
									<Col sm={8}>
										<Input type="number" name="currentDeviceCount" id="currentDeviceCount" readOnly value={customer.numberOfDevices}/>
									</Col>
								</FormGroup>
							</Form>
						</CardBody>
						<CardFooter className="text-right">
							<Button className="mr-1" color="danger" onClick2={this.toggle} onClick={() => {this.deleteDialog.toggle();}}>Löschen</Button>
							<Link to={toDetails} className="btn btn-primary">Ändern</Link>
						</CardFooter>
					</Card>
				</Col>
				<Col md="6">
					<Card>
						<CardHeader>Sessioninformationen</CardHeader>
						<CardBody>
							<Form>
								<FormGroup row>
									<Label for="created" sm={3}>Session-ID:</Label>
									<Col sm={9}>
										<Input type="datetime" name="created" id="created" readOnly value={customer.currentSessionId}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="ipaddress" sm={3}>IP-Adresse:</Label>
									<Col sm={9}>
										<Input type="datetime" name="ipaddress" id="ipaddress" readOnly value={customer.currentIp}/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="lastActiveCustomer" sm={3}>Login:</Label>
									<Col sm={9}>
										<Input type="datetime" name="lastActiveCustomer" id="lastActiveCustomer" readOnly value={login}/>
									</Col>
								</FormGroup>
							</Form>
						</CardBody>
					</Card>
				</Col>
			  </Row>
			</Container>

			<Container>
				<Row>
					{customer.devices.map(
						(dev, index) => <Device key={index} device={ {...dev, number: index} } deleteAction={() => {this.deleteDevice(dev.id, dev.description);}} />
					)}
				</Row>
			</Container>

		</React.Fragment>);
	}
}

const mapStateToProps = (state, ownProps) => {
	//console.log(state);
	//console.log(ownProps);

	return {
		customerName: ownProps.match.params.userName,
		customer: state.customers.currentCustomer,
		isNetworking: state.customers.networking
	}
}

const mapDispatchToProps = dispatch => ({
	fetch: (id) => dispatch(fetchCustomer(id)),
	remove: (id) => dispatch(deleteCustomer(id)),
	removeDevice: (id) => dispatch(deleteDeviceOfCurrentCustomer(id))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Customer)
