import React, {Component} from "react";
import { Route, Switch, Link, withRouter } from 'react-router-dom'
import { Container, Collapse, Navbar, NavbarToggler, Nav, NavItem, NavLink, Form, Button,
 	UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import { getLastError, isLoggedIn } from '../reducers/index'
import { logOut, logInWithCookie } from '../actions/login'
import { connect } from 'react-redux'

import { Home } from "./Home"
import { Error } from "./Error"
import Login from "./Login"
import CustomerDetails from "./customers/CustomerDetails"
import Customers from "./customers/Customers"
import Customer from "./customers/Customer"
import Device from "./device/Device"

//import EmployeeList from "./components/EmployeeList";
//import Client from "./components/Client";
//import Header from "./components/Header";
const LoginButton = (props) => {
	const { isLoggedIn, logout } = props;
	if (isLoggedIn) {
		return (
			<Form inline className="mt-2 mt-md-0">
				<Button outline color="success" className="my-2 my-sm-0" onClick={(e) => {e.preventDefault(); logout();}}>Logout</Button>
			</Form>
		);
	} else {
		return "";
	}
}

const Menu = (props) => {
	const {isLoggedIn} = props;
	if (isLoggedIn) {
		return (<React.Fragment>
			<UncontrolledDropdown>
				<DropdownToggle nav caret>
					Kunden
				</DropdownToggle>
				<DropdownMenu>
					<Link to="/customers/new" className="dropdown-item">Neu</Link>
					<Link to="/customers" className="dropdown-item">Liste</Link>
				</DropdownMenu>
			</UncontrolledDropdown>
			<NavItem>
				<NavLink href="http://www.zilox-it.de">ZiLoX IT</NavLink>
			</NavItem>
		</React.Fragment>);
	}
	else {
		return "";
	}
}

const Content = (props) => {
	const { isLoggedIn } = props;
	if (isLoggedIn) {
		return (
			<Switch>
				<Route exact path={["/", "/index", "/index.html"]} component={Home} />
				<Route exact path="/customers/new" render={(props) => <CustomerDetails {...props} />} />
				<Route exact path="/customers/:userName/edit" render={(props) => <CustomerDetails {...props} />} />
				<Route path="/customers/:userName" component={Customer} />
				<Route path="/customers" component={Customers} />
				<Route path="/device/:deviceId" component={Device} />
			</Switch>
		);
	}
	else {
		return (
			<Login />
		);
	}
}

class App extends Component {

    constructor(props) {
        super(props);

		this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
			collapsed: true
		};
    }

	componentDidMount() {
		this.props.tryLogin();
	}

    toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

    render() {
		const { isLoggedIn } = this.props;

        return (
			<div>
            <header>
				<Navbar color="dark" dark expand="md" className="mb-4">
					<Link to="/" className="navbar-brand">DWine Cloud API</Link>
					<NavbarToggler onClick={this.toggleNavbar} />
					<Collapse isOpen={!this.state.collapsed} navbar>
		                <Nav navbar className="mr-auto">
							<Menu isLoggedIn={isLoggedIn} />
						</Nav>
						<LoginButton isLoggedIn={isLoggedIn} logout={this.props.logout} />
					</Collapse>
				</Navbar>
			</header>

			<main role="alert">
				<Error error={this.props.lastError} />
			</main>

			<main role="main">
				<Content isLoggedIn={isLoggedIn} />
			</main>

			<footer className="footer">
				<Container>
					<span className="text-muted">© Copyright by <a href="https://www.zilox-it.de" target="_blank" rel="noopener noreferrer">ZiLoX IT</a> GbR 2018-2019</span>
				</Container>
			</footer>

			</div>
        )
    }
}

const mapStateToProps = state => ({
	lastError: getLastError(state),
	isLoggedIn: isLoggedIn(state)
})

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(logOut()),
	tryLogin: () => dispatch(logInWithCookie())
})

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(App))
