import {
	LOAD_DEVICE_SUCCESS, LOADING_DEVICE, DELETING_DEVICE, DEVICE_DELETED
} from '../actions/devices';

const initialState = {
	current: null,
	networking: false
};

export default function devices(state = initialState, action) {
	switch (action.type) {
		case LOAD_DEVICE_SUCCESS: {
			return {
				...state,
				networking: false,
				current: action.payload
			}
		}

		case LOADING_DEVICE: {
			return {
				...state,
				networking: true,
				current: null
			}
		}

		case DELETING_DEVICE: {
			return {
				...state,
				networking: action.payload
			}
		}

		case DEVICE_DELETED: {
			return {
				...state,
				networking: false,
				current: null
			}
		}

		default:
			//console.error("Unhandled action: " + action);
			return state;
	}
}

export function getCurrentDevice(state) {
	return state.devices.current;
}
