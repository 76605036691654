//import 'babel-polyfill'

import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';

import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { createBrowserHistory } from 'history'
import { routerMiddleware, ConnectedRouter } from 'connected-react-router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser, faKey, faSignInAlt, faSpinner, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { faClock, faCalendarPlus } from '@fortawesome/free-regular-svg-icons'

import reducer from './reducers/index';
import App from './components/App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-widgets/dist/css/react-widgets.css';
import './index.css';

import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';

//import 'moment/moment.js';
//import 'moment/min/locales.min.js';

Moment.locale('de');
momentLocalizer();

library.add(faUser, faKey, faSignInAlt, faSpinner, faCalendarPlus, faClock, faExchangeAlt);

const history = createBrowserHistory();
const reactRouterMiddleWare = routerMiddleware(history);

let middleware = [reactRouterMiddleWare, thunk];
if (process.env.NODE_ENV !== 'production') {
	middleware = [...middleware, createLogger() ]
};

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);
export const store = createStoreWithMiddleware(reducer(history));

/*const store = createStore(
	reducer,
	{},
	applyMiddleware(...middleware)
);*/

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App />
		</ConnectedRouter>
	</Provider>
  , document.getElementById('root'));
//<ConnectedRouter history={history}>
registerServiceWorker();
