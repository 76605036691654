import fetch from 'cross-fetch'
import { showError, hideError } from './index'

import moment from 'moment/moment.js'
import cookie from 'react-cookies'

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

const expirationMillis = 30*60*1000;	// 30 mins.

let loginRefreshTimer = null;

function startLoginRefreshTimer(timeout) {
	return (dispatch) => {
		clearTimeout(loginRefreshTimer);
		if (timeout!==undefined && timeout!==null) {
			let now = new moment();
			if (now.isBefore(timeout)) {
				let timeoutMSec = moment.duration(timeout.diff(now)).asMilliseconds();
				console.debug("Starting refresh login timer in " + timeoutMSec+" milliseconds.");
				loginRefreshTimer = setTimeout( () => dispatch(refreshLogin()), timeoutMSec );
			}
			else {
				console.debug("Token has expired - refresh timer not started!");
			}
		}
	}
}

function stopLoginRefreshTimer() {
	console.debug("Stopping refresh login timer.");
	clearTimeout(loginRefreshTimer);
}

function refreshLogin() {
	return (dispatch, getState) => {
		console.debug("Refreshing login token");
		let state = getState();
		let token = state.app.token;
		let user = state.app.user;
		if (token===null || user===null) {
			console.warn("Token or user is not available - abort refreshLogin.");
			return;
		}

		let data = new URLSearchParams();
		data.append("username", user.username);
		data.append("expirationMillis", expirationMillis);
		fetch('/api/fetch-new-auth-token', {
			method: "post",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/x-www-form-urlencoded",
				"Authorization": token
			},
			body: data
		})
		.then( (response) => handleReLoginResponse(dispatch, response, user) );
	}
}

function dispatchLogin(dispatch, user, token) {
	let timeout = moment().add(expirationMillis*0.9, 'milliseconds');
	cookie.save('dwineCloudAccess', {
		user: user,
		token: token,
		timeout: timeout
	}, {path:'/'});
	dispatch({
		type: LOGIN,
		"token": token,
		"user": user,
		"timeout": timeout
	});
	dispatch(startLoginRefreshTimer(timeout));
}

async function handleReLoginResponse(dispatch, response, user) {
	console.log(response);
	let json = {
		status: response.status,
		error: response.statusText,
		path: response.url
	}
	let token = null;
	try {
		json = await response.json();
		if (json.token!==undefined) token = json.token;
	}
	catch(e) {}
	if (response.ok && token!=null) {
		dispatchLogin(dispatch, user, token);
	} else {
		dispatch(showError(json));
	}
}

async function handleLoginResponse(dispatch, response) {
	let json = {
		status: response.status,
		error: response.statusText,
		path: response.url
	}
	let token = null;
	if (response.headers.has("cloud-authorization")) {
		token = response.headers.get("cloud-authorization");
	}
	try {
		json = await response.json();
	}
	catch(e) {}
	if (response.ok && token!=null) {
		dispatchLogin(dispatch, json, token);
	} else {
		dispatch(showError(json));
	}
}

export function logOut() {
	cookie.remove('dwineCloudAccess', {path:'/'});
	stopLoginRefreshTimer();
	return {
		type: LOGOUT
	}
}

export function logInWithCookie() {
	return dispatch => {
		let data = cookie.load('dwineCloudAccess');
		if (data!==undefined) {
			let timeout = data.timeout!==undefined ? moment(data.timeout) : null;
			if (timeout!==null) {
				dispatch(startLoginRefreshTimer(timeout));
				dispatch({
					type: LOGIN,
					token: data.token,
					user: data.user,
					timeout: timeout
				});
			}
		}
	}
}

export function logIn(username, password) {
	return dispatch => {
		dispatch(hideError());
		let data = new URLSearchParams();
		data.append("username", username);
		data.append("password", password);
		data.append("expirationMillis", expirationMillis);
		fetch('/api/login.do', {
				method: "post",
				headers: {
					"Accept": "application/json",
					"Content-Type": "application/x-www-form-urlencoded"
				},

				body: data
			})
			.then( (response) => handleLoginResponse(dispatch, response) );
	}
}
