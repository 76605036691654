import React, {Component} from "react"

import { updateCustomer, saveCustomer, clearCustomer, fetchCustomer } from '../../actions/customers'
import { redirect } from '../../actions/index'
import { getCurrentCustomer, getNewCustomer } from '../../reducers/customers'
import { connect } from 'react-redux'


import { Container, Col, Card, CardBody, CardHeader, CardFooter,
 	Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { DateTimePicker } from 'react-widgets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

class CustomerDetails extends Component {

	componentDidMount() {
		const { customerName, customer } = this.props;

		if (customerName!==undefined) {
			if (!customer.hasChanged || customer.username!==customerName) this.props.fetch();
		}
	}

	render() {
		const { update, save, back, customer, isSaving, isLoading, customerName } = this.props;

		if (isLoading) {
			return (
				<Container className="mb-2">
					<h2>Kunde</h2>
					<p><FontAwesomeIcon icon="spinner" spin /></p>
				</Container>
			)
		}

		const savingDisabled = isSaving || !customer.hasChanged
		const created = moment(customer.created).format('LLL');
		const validUntil = customer.validUntil ? moment(customer.validUntil).toDate() : null;
		return (
			<Container className="mb-2">
				<h2>{customerName===undefined ? "Neuer Kunde" : customerName + " ändern"}</h2>
				<Card>
					<CardHeader>Kundeninformation</CardHeader>
					<CardBody>
						<Form>
							<FormGroup row>
								<Label for="username" sm={2}>Benutzername:</Label>
								<Col sm={10}>
									<Input name="username" id="username" value={customer.username} onChange={(event) => update({username: event.target.value})}/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="password" sm={2}>Passwort:</Label>
								<Col sm={10}>
									<Input type="password" name="password" id="password" onChange={(event) => update({password: event.target.value})}/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="description" sm={2}>Beschreibung:</Label>
								<Col sm={10}>
									<Input name="description" id="description" value={customer.description} onChange={(event) => update({description: event.target.value})} />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="maxDevices" sm={2}>Verfügbare Geräte:</Label>
								<Col sm={10}>
									<Input type="number" name="maxDevices" id="maxDevices" min="0" value={customer.maxDevices} onChange={(event) => update({maxDevices: event.target.value})} />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="validUntil" sm={2}>Verfügbar bis:</Label>
								<Col sm={10}>
									<DateTimePicker id="validUntil" name="validUntil" format="LLL" editFormat="DD.MM.YYYY HH:mm" value={validUntil} onChange={(value) => update({validUntil: value})} />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="isEnabled" sm={2}>Aktiviert:</Label>
								<Col sm={10}>
									<Input type="checkbox" name="isEnabled" id="isEnabled" checked={customer.isEnabled} onChange={(event) => update({isEnabled: !customer.isEnabled})} />
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label for="created" sm={2}>Angelegt am:</Label>
								<Col sm={10}>
									<Input type="datetime" name="created" id="created" readOnly value={created}/>
								</Col>
							</FormGroup>
						</Form>
					</CardBody>
					<CardFooter className="text-right">
						<Button className="mr-2" disabled={savingDisabled} color="success" onClick={(e) => {e.preventDefault(); save(); }}>Speichern</Button>
						<Button color="secondary" onClick={(e) => {e.preventDefault(); back(); }}>Zurück</Button>
					</CardFooter>
				</Card>
			</Container>);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		customerName: ownProps.match.params.userName,
		customer:  ownProps.match.params.userName===undefined ? getNewCustomer(state) : getCurrentCustomer(state),
		isLoading: state.customers.networking,
		isSaving: state.customers.networking
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	const customerName = ownProps.match.params.userName;
	return {
		update: (json) => dispatch(updateCustomer(customerName, json)),
		clear: () => dispatch(clearCustomer(customerName)),
		save: () => dispatch(saveCustomer(customerName)),
		fetch: () => dispatch(fetchCustomer(customerName)),
		back: () => dispatch(redirect("/customers/"+customerName))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomerDetails)
