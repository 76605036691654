import fetch from 'cross-fetch'
import withQuery from './withQuery'
import { showError, hideError, handleError, redirect } from './index'

export const LOAD_CUSTOMERS_SUCCESS = 'LOAD_CUSTOMERS_SUCCESS';
function loadCustomersSuccess(json) {
	return {
		type: LOAD_CUSTOMERS_SUCCESS,
		payload: json
	}
}

export const LOADING_CUSTOMERS = 'LOADING_CUSTOMERS';
function loadingCustomers() {
	return {
		type: LOADING_CUSTOMERS
	}
}

export function fetchCustomers() {
	return (dispatch, getState) => {
		dispatch(hideError());
		dispatch(loadingCustomers());
		let token = getState().app.token;
		return fetch('/api/customers.json', {
				headers: {
					'Authorization': token
				}
			})
			.then(handleError)
			.then(response => response.json())
			.then(json => {
				dispatch(loadCustomersSuccess(json));
				return json;
			})
			.catch(error => {
				dispatch(showError(error));
				dispatch(loadCustomersSuccess([]));
			});
	}
}

export const LOAD_CUSTOMER_SUCCESS = 'LOAD_CUSTOMER_SUCCESS';
function loadCustomerSuccess(customerName, json) {
	return {
		type: LOAD_CUSTOMER_SUCCESS,
		payload: json,
		customerName: customerName
	}
}

export const LOADING_CUSTOMER = 'LOADING_CUSTOMER';
function loadingCustomer(customerName) {
	return {
		type: LOADING_CUSTOMER,
		customerName: customerName
	}
}

export function fetchCustomer(customerName) {
	console.debug("fetchCustomer : " + customerName);

	return (dispatch, getState) => {
		dispatch(hideError());
		dispatch(loadingCustomer(customerName));
		let token = getState().app.token;
		return fetch(withQuery('/api/customer.json', {"username": customerName}), {
					headers: {
						'Authorization': token
					}
			})
			.then(handleError)
			.then(response => response.json())
			.then(json => {
				dispatch(loadCustomerSuccess(customerName, json));
				return json;
			})
			.catch(error => {
				dispatch(showError(error));
				dispatch(loadCustomerSuccess(customerName, null));
			});
	}
}

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export function updateCustomer(customerName, json) {
	return {
		type: UPDATE_CUSTOMER,
		payload: json,
		customerName: customerName
	}
}
export function clearCustomer(customerName) {
	return {
		type: UPDATE_CUSTOMER,
		customerName: customerName,
		payload: {
			username: "", password: "", maxDevices: 0, isEnabled: false, hasChanged: false
		}
	}
}

export const SAVING_CUSTOMER = 'SAVING_CUSTOMER';
function savingCustomer(customerName, saving) {
	return {
		type: SAVING_CUSTOMER,
		customerName: customerName,
		payload: saving===undefined ? true : saving
	}
}

export function saveCustomer(customerName) {
	return (dispatch, getState) => {
		dispatch(hideError());
		dispatch(savingCustomer(customerName));
		let state = getState();
		let token = state.app.token;
		let customer = customerName!==undefined ? state.customers.currentCustomer : state.customers.newCustomer;
		if (customer===null) return;
		let username = customer.username;
		if (customerName!==undefined) username = customerName;
		return fetch(withQuery('/api/customer.json', {"username": username}), {
				method: "post",
				headers: {
					'Authorization': token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(customer)
			})
			.then(handleError)
			.then(response => response.json())
			.then(json => {
				if (customerName!==username) {
					dispatch(clearCustomer(customerName));
					dispatch(redirect("/customers/"+username));
				}
				else {
					dispatch(loadCustomerSuccess(username, json));
				}
				return json;
			})
			.catch(error => {
				dispatch(showError(error));
				dispatch(savingCustomer(customerName, false));
			});
	}
}

export const DELETING_CUSTOMER = 'DELETING_CUSTOMER';
function deletingCustomer(deleting) {
	return {
		type: DELETING_CUSTOMER,
		payload: deleting===undefined ? true : deleting
	}
}

export function deleteCustomer(customerName) {
	console.debug("deleteCustomer : " + customerName);

	return (dispatch, getState) => {
		if (customerName===undefined) return;
		let state = getState();
		dispatch(hideError());
		dispatch(deletingCustomer());
		let token = state.app.token;
		return fetch(withQuery('/api/customer.json', {"username": customerName}), {
				method: "delete",
				headers: {
					'Authorization': token,
					'Content-Type': 'application/json'
				}
			})
			.then(handleError)
			.then(response => {
				dispatch(redirect("/customers"));
				//dispatch(deletingCustomer(false));
				dispatch(clearCustomer(customerName));
				return response;
			})
			.catch(error => {
				dispatch(showError(error));
				dispatch(deletingCustomer(false));
			});
	}
}

export const DELETING_DEVICE_OF_CUSTOMER = 'DELETING_DEVICE_OF_CUSTOMER';
function deletingDeviceOfCustomer(deviceId, deleting) {
	return {
		type: DELETING_DEVICE_OF_CUSTOMER,
		payload: {
			deleting: deleting===undefined ? true : deleting,
			deviceId: deviceId
		}
	}
}

export const DEVICE_OF_CUSTOMER_DELETED = 'DEVICE_OF_CUSTOMER_DELETED';
function deviceOfCustomerDeleted(deviceId) {
	return {
		type: DEVICE_OF_CUSTOMER_DELETED,
		payload: deviceId
	}
}

export function deleteDeviceOfCurrentCustomer(deviceId) {
	console.debug("deleteDeviceOfCurrentCustomer : " + deviceId);

	return (dispatch, getState) => {
		if (deviceId===undefined) return;
		let state = getState();
		dispatch(hideError());
		dispatch(deletingDeviceOfCustomer(deviceId));
		let token = state.app.token;
		return fetch(withQuery('/api/device.json', {"id": deviceId}), {
				method: "delete",
				headers: {
					'Authorization': token,
					'Content-Type': 'application/json'
				}
			})
			.then(handleError)
			.then(response => {
				dispatch(deviceOfCustomerDeleted(deviceId));
				return response;
			})
			.catch(error => {
				dispatch(showError(error));
				dispatch(deletingDeviceOfCustomer(deviceId, false));
			});
	}
}
