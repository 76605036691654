import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import { default as customers } from './customers';
import { default as devices } from './devices';

import { TRANSFER_ERROR } from '../actions/index';
import { LOGIN, LOGOUT } from '../actions/login';

const initialState = {
	error: null,
	token: null,
	user: null,
	timeout: null
};

export function app(state = initialState, action) {
	switch (action.type) {
		case TRANSFER_ERROR: {
			if (action.lastError!==null) console.error(action.lastError.message);
			return {
				...state,
				error: action.lastError
			}
		}

		case LOGIN: {
			return {
				...state,
				token: action.token,
				user: action.user,
				timeout: action.timeout
			}
		}

		case LOGOUT: {
			return {
				...state,
				token: null,
				user: null,
				timeout: null
			}
		}

		default:
			//console.error("Unhandled action: " + action);
			return state;
	}
}

export function getLastError(state) {
	return state.app.error;
}

export function isLoggedIn(state) {
	return state.app.token!==undefined && state.app.token!==null
		&& state.app.timeout!==undefined && state.app.timeout!==null;
}

const appReducer = (history) => combineReducers({
	app,
    customers,
	devices,
	router: connectRouter(history)
});

const rootReducer = (history) => (state, action) => {
	if (action.type === 'LOGOUT') {
		state = undefined;
	}
	return appReducer(history)(state, action);
}
export default rootReducer;
