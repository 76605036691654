
import React, {Component} from "react";
import { connect } from 'react-redux'
import { Container, Jumbotron, Row, Col,
	Form, FormGroup, Button, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { logIn } from '../actions/login'

import Logo from '../images/dwine.svg'

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			e_username: null,
			e_password: null
		}
	}

	render() {
		return (
			<Container>
				<Jumbotron>
					<Row>
						<Col md="4">
							<img width="100%" src={Logo} alt="DWine System Logo" />
						</Col>
						<Col md="8">
							<h1 className="display-6">DWine Cloud API</h1>
							<p className="lead">Frontend</p>
							<Form>
								<FormGroup>
									<Label for="username">Benutzername</Label>
									<InputGroup>
										<InputGroupAddon addonType="prepend"><InputGroupText><FontAwesomeIcon icon="user" /></InputGroupText></InputGroupAddon>
										<Input type="text" name="username" id="username" placeholder="Anmeldename für die DWine Cloud API" innerRef={(e) => this.username = e}/>
									</InputGroup>
								</FormGroup>
								<FormGroup>
									<Label for="password">Passwort</Label>
									<InputGroup>
										<InputGroupAddon addonType="prepend"><InputGroupText><FontAwesomeIcon icon="key" /></InputGroupText></InputGroupAddon>
										<Input type="password" name="password" id="password" placeholder="zugehöriges Passwort" innerRef={(e) => this.password = e} />
									</InputGroup>
								</FormGroup>

								<Button onClick={() => this.props.logIn(this.username.value, this.password.value)}><FontAwesomeIcon icon="sign-in-alt" /> Anmelden</Button>
							</Form>
						</Col>
					</Row>
				</Jumbotron>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => ({
	logIn: (username, password) => dispatch(logIn(username, password))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Login)
