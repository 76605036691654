import React, {Component} from "react"
import PropTypes from 'prop-types'

import { fetchCustomers } from '../../actions/customers'
import { getCustomers } from '../../reducers/customers'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'
import { Container, Row, Col, Card, CardHeader, CardFooter, CardBody, CardSubtitle, CardText, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import moment from 'moment/moment.js'
import {} from 'moment/locale/de.js'

const Customer = ({customer, showCustomer}) => {
	const to = "/customers/"+customer.username;
	const created = moment(customer.created).format('LLL');
	return (
	<Col sm="6" md="4" lg="3" className="mb-2">
		<Card>
			<CardHeader>{customer.username}	{customer.currentSessionId ? <Badge color="success">online</Badge> : ""} {!customer.isEnabled ? <Badge color="warning">disabled</Badge> : ""}</CardHeader>
			<CardBody>
				<CardSubtitle>{customer.description}</CardSubtitle>
				<CardText>
					{created}<br />
					Geräte: {customer.numberOfDevices} / {customer.maxDevices}<br />
				</CardText>
			</CardBody>
			<CardFooter className="text-right"><Link to={to} className="btn btn-outline-primary">Details</Link></CardFooter>
		</Card>
	</Col>
)}
Customer.propTypes = {
	customer: PropTypes.object.isRequired
}
//export Customer;

const CustomerList = ({customers, showCustomer}) => (
			<Row>
				{customers.map(
					c => <Customer key={c.username} customer={c} />
				)}
			</Row>
)
CustomerList.propTypes = {
	customers: PropTypes.array.isRequired
}
//export CustomerList;



class Customers extends Component {

	componentDidMount() {
		if (!this.props.isLoading) this.props.fetch();
	}

	render() {
		if (this.props.isLoading) {
			return (
				<Container>
					<h2>Kunden</h2>
					<p><FontAwesomeIcon icon="spinner" spin /></p>
				</Container>
			)
		}
		else {
			return (
				<Container>
					<h2>Kunden</h2>
					<CustomerList customers={this.props.customers} />
				</Container>
			);
		}
	}
}

const mapStateToProps = state => ({
	customers: getCustomers(state),
	isLoading: state.customers.networking
})

const mapDispatchToProps = dispatch => ({
	fetch: () => dispatch(fetchCustomers())
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Customers)
