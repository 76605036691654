import { TransferError } from '../objects/TransferError'
import { LOGOUT } from './login'

import { push } from 'connected-react-router'

export const TRANSFER_ERROR = 'TRANSFER_ERROR';
export function showError(json) {
	return (dispatch) => {
		dispatch({
			type: TRANSFER_ERROR,
			lastError: json
		});
		if (json!==null && json.status===401) {
			dispatch({
				type: LOGOUT
			});
		}
	}
}

export function hideError() {
	return {
		type: TRANSFER_ERROR,
		lastError: null
	}
}

export async function handleError(response) {
	if (!response.ok) {
		let json = {
			status: response.status,
			error: response.statusText,
			path: response.url
		}
		try {
			json = await response.json();
		}
		catch(e) {}
		throw new TransferError(json);
	}
	return response;
}

export function redirect(target) {
	return (dispatch) => {
		dispatch(push(target));
	}
}
