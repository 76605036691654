import React, {Component} from "react"

import { fetchDevice } from '../../actions/devices'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Container, Col, Card, CardBody, CardFooter, CardSubtitle } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class DeviceLog extends Component {

	componentDidMount() {
		//console.log(this.props);
		if (!this.props.isLoading) this.props.fetch(this.props.deviceId);
	}

	render() {
		const { isLoading, device } = this.props;

		if (isLoading || device==null) {
			return (
				<Container>
					<h2>Geräte-Log</h2>
					<p><FontAwesomeIcon icon="spinner" spin /></p>
				</Container>
			)
		}

		const toInfo = "/device/"+device.id;
		return (
			<Container>
				<h2>Geräte-Log</h2>
				<Col md="12" className="mb-2">
					<Card>
						<CardBody>
							<CardSubtitle><small>ID: {device.id}</small></CardSubtitle>

						</CardBody>
						<CardFooter className="text-right">
							<Link to={toInfo} className="btn btn-primary">Info</Link>
						</CardFooter>
					</Card>
				</Col>
			</Container>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		device: state.devices.current,
		isLoading: state.devices.networking
	}
}

const mapDispatchToProps = dispatch => ({
	fetch: (id) => dispatch(fetchDevice(id))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DeviceLog)
