import React from "react";
import { Container, Alert } from 'reactstrap';

const Error401 = ({error}) => {
	return (
		<Container>
			<Alert color="danger">
				<h3 className="alert-heading">{error.status} {error.error}</h3>
				<p>{error.path}<br />
					{error.message}</p>
				<hr />
				<p className="mb-0">
					Bitte melden Sie sich (neu) an.
				</p>
			</Alert>
		</Container>
	);
}

const ErrorDefault = ({error}) => {
	return (
		<Container>
			<Alert color="danger">
				<h3 className="alert-heading">{error.status} {error.error}</h3>
				<p>{error.path}<br />
					{error.message}</p>
			</Alert>
		</Container>
	);
}

export const Error = ({error}) => {
	if (error!==null && error!==undefined) {
		if (error.status===401) {
			return (<Error401 error={error} />);
		}
		else {
			return (<ErrorDefault error={error} />);
		}
	}
	else return "";
}
