import React from "react"
import { Route, Switch } from 'react-router-dom'

import { connect } from 'react-redux'

import DeviceInfo from './DeviceInfo';
import DeviceLog from './DeviceLog';

const Device = (props) => {
	const { match, deviceId } = props;

	return (
		<Switch>
			<Route path={`${match.url}/log`} render={(props) => <DeviceLog deviceId={deviceId} {...props} />} />
			<Route exact path={`${match.url}/`} render={(props) => <DeviceInfo deviceId={deviceId} {...props} />} />
		</Switch>
	);
}


const mapStateToProps = (state, ownProps) => {
	return {
		deviceId: ownProps.match.params.deviceId
	}
}

const mapDispatchToProps = dispatch => ({
	//fetch: (id) => dispatch(fetchCustomer(id))
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Device)
