import {
	LOAD_CUSTOMER_SUCCESS, LOADING_CUSTOMER, LOAD_CUSTOMERS_SUCCESS, LOADING_CUSTOMERS,
	UPDATE_CUSTOMER, SAVING_CUSTOMER, DELETING_CUSTOMER, DELETING_DEVICE_OF_CUSTOMER, DEVICE_OF_CUSTOMER_DELETED
} from '../actions/customers';

const initialState = {
	currentCustomer: {},
	newCustomer: {},

	networking: false,

	customers: [ ]
};

export default function customers(state = initialState, action) {
	switch (action.type) {
		case LOAD_CUSTOMERS_SUCCESS: {
			return {
				...state,
				networking: false,
				customers: action.payload
			}
		}

		case LOADING_CUSTOMERS: {
			return {
				...state,
				networking: true,
				customers: []
			}
		}

		case LOADING_CUSTOMER: {
			if (action.customerName===undefined) {
				return {
					...state,
					networking: true,
					newCustomer: {}
				}
			}
			return {
				...state,
				networking: true,
				currentCustomer: {}
			}
		}

		case LOAD_CUSTOMER_SUCCESS: {
			if (action.customerName===undefined) {
				return {
					...state,
					networking: false,
					newCustomer: {...action.payload, hasChanged: false}
				}
			}
			return {
				...state,
				networking: false,
				currentCustomer: {...action.payload, hasChanged: false}
			}
		}

		case SAVING_CUSTOMER: {
			if (action.customerName===undefined) {
				return {
					...state,
					networking: action.payload,
					newCustomer: {...state.newCustomer, hasChanged: true}
				}
			}
			return {
				...state,
				networking: action.payload,
				currentCustomer: {...state.currentCustomer, hasChanged: true}
			}
		}

		case UPDATE_CUSTOMER: {
			if (action.customerName===undefined) {
				return {
					...state,
					networking: false,
					newCustomer: {...state.newCustomer, hasChanged: true, ...action.payload }
				}
			}
			return {
				...state,
				networking: false,
				currentCustomer: {...state.currentCustomer, hasChanged: true, ...action.payload }
			}
		}

		case DELETING_CUSTOMER: {
			return {
				...state,
				networking: action.payload
			}
		}

		case DELETING_DEVICE_OF_CUSTOMER: {
			return {
				...state,
				networking: action.payload.deleting
			}
		}

		case DEVICE_OF_CUSTOMER_DELETED: {
			const deletedDeviceId = action.payload
			let currentCustomer = state.currentCustomer;
			let devices = currentCustomer.devices;
			let newDevices = [];
			for(var idx=0; idx<devices.length; ++idx) {
				if (devices[idx].id!==deletedDeviceId) {
					newDevices.push(devices[idx]);
				}
			}
			return {
				...state,
				networking: false,
				currentCustomer: {...state.currentCustomer, devices: newDevices }
			}
		}

		default:
			//console.error("Unhandled action: " + action);
			return state;
	}
}

export function getCurrentCustomer(state) {
	return state.customers.currentCustomer;
}

export function getNewCustomer(state) {
	return state.customers.newCustomer;
}

export function getCustomers(state) {
	return state.customers.customers;
}
